import React from 'react'

const Newsletter = () => {
  return (
    <div id='contacto' className='w-full py-16 bg-white text-black mx-auto grid lg:grid-cols-3 px-4'>
        <div className='lg:col-span-2 my-4'>
            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Quieres consejos de Marketing para aumentar tu flujo de ventas?</h1>
            <p className=''>Suscríbete a nuestro Newsletter para recibir datos importantes!</p>
        </div>
        <div className='my-4'>
            <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
                <input className='p-3 flex w-full rounded-md text-white bg-black'  type="email" placeholder='Ingresa tu E-mail' />
                <button className='bg-[#00df9a] w-[200px] rounded-md my-6 mx-auto py-3 px-6 ml-4 font-black text-black'>Avísame!</button>
            </div>
        </div>
    </div>
  )
}

export default Newsletter