import './App.css';
import BotonSubir from './components/BotonSubir';
import Contacto from './components/Contacto';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Newsletter from './components/Newsletter';
import Nosotros from './components/Nosotros';
import Portafolio from './components/Portafolio';
import Servicios from './components/Servicios';

function App() {
  return (
    <div>
      <BotonSubir/>
      <Navbar/>
      <Hero />

      <Nosotros />
      <Servicios />
      <Portafolio />
      <Contacto />

      <Newsletter/>
      <Footer/>
    </div>
  );
}

export default App;
