import React from 'react'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import { IoHomeOutline } from "react-icons/io5";
import JoelPerfil from './Assets/JoelPerfil.webp'
import NavbarBlog from '../../components/NavbarBlog';

const ArticuloUno = () => {
  return (
    <div>
        <NavbarBlog/>
        
        <div className='mt-20 mx-auto w-full h-full min-h-[80vh]'>
            <div className='w-[90%] mx-auto'>
                <Link to="/blog">
                    <div className='flex mx-auto'>
                        <p className=' flex gap-2 mb-3 items-center h-full text-[#00df9a] hover:border-b-2 hover:border-solid hover:border-b-[#00df9a]'> <IoHomeOutline size={30} color='#00df9a' />Volver a los Blogs</p>
                    </div>
                </Link>
                
                <p className='text-white text-[0.9rem]'>Publicado el 15 de Abril, 2024. Por @JoelDev</p>
                <h1 className='text-[#00df9a] text-3xl font-bold '>
                    Rangos de Programadores
                </h1>
                
            </div>

            <div className='inline-flex items-center justify-center w-full'>
                <hr class="w-[90%] h-1 my-4 bg-gray-200 border-0 rounded dark:bg-gray-700" />
            </div>

            <div className='w-[90%] mx-auto grid grid-cols-1 md:grid-cols-2'>

                <div className='text-white mx-auto'>
                    <h3 className='text-lg pb-6 font-bold'>
                        Navegando por los rangos en el mundo de la informática: Salarios y cómo escalar
                    </h3>
                    <p>
                    ¡Hola estimados estudiantes de informática! Hoy nos adentramos en el fascinante mundo laboral de la tecnología, explorando los diferentes rangos profesionales que existen, sus rangos de salario promedio en dólares estadounidenses y, lo más importante, cómo ascender en esta escalera de conocimiento y experiencia.
                    </p>
                    <h3 className='font-bold text-[#00df9a] text-lg py-4'>Los rangos en el mundo de la informática:</h3>
                    <ul className='flex flex-col gap-6'>
                        <li>
                            <strong>Junior:</strong>
                            <p>Son los recién llegados, aquellos que dan sus primeros pasos en el ámbito profesional. Se espera que tengan conocimientos básicos en lenguajes de programación, estructuras de datos y algoritmos. Su salario promedio se encuentra entre $40.000 y $60.000 USD anuales.</p>
                        </li>
                        <li>
                            <strong>Semi Senior:</strong>
                            <p>Con un par de años de experiencia bajo la manga, los semi senior han adquirido mayor dominio en sus habilidades técnicas y resolución de problemas. Aportan valor a los proyectos con su capacidad de análisis y diseño de soluciones. Su salario promedio ronda entre $60.000 y $80.000 USD anuales.</p>
                        </li>
                        <li>
                            <strong>Senior:</strong>
                            <p>Los expertos en la sala. Poseen una profunda comprensión de la informática y son capaces de liderar equipos, mentorizar a sus colegas y abordar proyectos complejos con total autonomía. Su salario promedio oscila entre $80.000 y $120.000 USD anuales.</p>
                        </li>
                    </ul>
                </div>

                <div className='bg-white ml-10'>
                    
                </div>

            </div>

        </div>

        <div className='inline-flex items-center justify-center w-full'>
                <hr class="w-[90%] h-1 my-4 bg-gray-200 border-0 rounded dark:bg-gray-700" />
        </div>

        <div className='w-[90%] mx-auto text-white'>
            <div>
                <h1 className='font-bold text-3xl mb-8'>Autor</h1>
            </div>
            <div className='flex flex-col max-w-[70%] mx-auto md:max-w-[100%] md:flex-row gap-8 md:ml-8'>
                <div className='flex'>
                    <img src={JoelPerfil} className='md:w-[200px] rounded-[50%] w-full  border-solid border-2 border-white' alt="" />
                </div>
                <div className='md:w-[40%] items-center my-auto'>
                    <h1 className='font-bold text-2xl'>Joel Herrera Leiva</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus cupiditate architecto sed necessitatibus optio voluptate quae aut nihil molestiae. Aperiam.</p>
                </div>
            </div>

        </div>

        <div className='w-[90%] mx-auto text-white pt-10'>
            <div>
                <h1 className='font-bold text-2xl py-4'>Etiquetas</h1>
            </div>
            <div className='flex gap-4'>
                <h1 className='bg-white text-black font-bold py-2 px-4 rounded-3xl'>Para estudiantes</h1>
                <h1 className='bg-white text-black font-bold py-2 px-4 rounded-3xl'>Informática</h1>
            </div>
        </div>


        <div className='inline-flex items-center justify-center w-full'>
                <hr class="w-[90%] h-1 my-4 bg-gray-200 border-0 rounded dark:bg-gray-700" />
        </div>

        <div>
            <div className='bg-white w-[90%] p-8 mx-auto flex flex-col mt-12 '>
                <h1 className='font-bold text-3xl'>Comentarios</h1>
                <div>
                    <form action="">
                        <div className='my-2'>
                            <label htmlFor=""></label>
                            <textarea type="text" className=' border-solid border-2 border-black w-full p-4' placeholder='Escribe aquí' />
                        </div>
                        <input type="submit" placeholder='Enviar'  className='  py-2 px-6 text-xl rounded-md bg-[#00df9a]'/>
                    </form>
                </div>
            </div>
        </div>


        <Footer/>
    </div>
  )
}

export default ArticuloUno