import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import { Link } from 'react-router-dom'

const Navbar = () => {
    const [nav, setNav] = useState(true)

    const handleNav = () => {
        setNav(!nav)
    }

  return (
    <div className='flex justify-between items-center bg-[#000300] w-full fixed top-0 z-50 h-16 mx-auto px-4 text-white shadow-lg shadow-[#242724]'>
        <div >
            <a className='w-full text-3xl font-bold flex hover:scale-105 duration-300' href="#inicio">
                <h1 className='text-[#00df9a]'>VENDE</h1>
                <h1 className='text-[#d0f8ec] ml-1'>PO!</h1>
            </a>        

        </div>
        <ul className='hidden md:flex'>
            <li className='p-4 hover:scale-105 duration-300'><a href="#nosotros">Nosotros</a></li>
            <li className='p-4 hover:scale-105 duration-300'><a href="#servicios">Servicios</a></li>
            <li className='p-4 hover:scale-105 duration-300'><a href="#portafolio">Portafolio</a></li>
            <li className='p-4 hover:scale-105 duration-300'><a href="#contacto">Contacto</a></li>
            <li className='p-4 hover:scale-105 duration-300'><Link to="/blog">Blogs</Link></li>
        </ul>


        <div onClick={handleNav} className='block md:hidden'>
            {!nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
            
        </div>


        <div className={!nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'fixed left-[-100%]'}>

            <div className='w-full text-3xl font-bold flex m-4'>        
                <a className='w-full text-3xl font-bold flex hover:scale-105 duration-300' href="#inicio">
                    <h1 className='text-[#00df9a]'>VENDE</h1>
                    <h1 className='text-[#d0f8ec] ml-1'>PO!</h1>
                </a>   
            </div>
            <ul className='p-4 uppercase'>
                <a href="#cartas" onClick={handleNav}><li className='p-4 hover:text-[#00df9a] border-b border-gray-600'><a href="#nosotros">Nosotros</a></li></a>
                <a href="#nosotros" onClick={handleNav}><li className='p-4 hover:text-[#00df9a] border-b border-gray-600'><a href="#servicios">Servicios</a></li></a>
                <a href="#nosotros" onClick={handleNav}><li className='p-4 hover:text-[#00df9a] border-b border-gray-600'><a href="#portafolio">Portafolio</a></li></a>
                <a href="#contacto" onClick={handleNav}><li className='p-4 hover:text-[#00df9a] border-b border-gray-600'>Contacto</li></a>
                <Link to='/blog/' onClick={handleNav}><li className='p-4 hover:text-[#00df9a] '>Blogs</li></Link>
                
            </ul>
        </div>
    </div>
  )
}

export default Navbar