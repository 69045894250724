import React from 'react';
import { ReactTyped } from "react-typed";

const Hero = () => {
  return (
    <div id='inicio' className='text-white pt-28 '>
        <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
            <p className='text-[#00df9a] font-bold p-2'>INVIERTE EN TU NEGOCIO PO!</p>
            <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>Diseñamos tu web</h1>
            <div className='flex justify-center items-center '>
                <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>Aumenta</ p>
                <ReactTyped className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 text-[#00df9a]' strings={['Ventas', 'Alcance','Clientes']} typeSpeed={120} backSpeed={140} loop />
            </div>
            <p className='md:text-2xl text-xl font-bold text-gray-500'>Creamos tu Landing Page y Asesoramiento de Marketing</p>
            <a className='bg-[#00df9a] w-[200px] hover:scale-105 duration-300  rounded-md my-6 mx-auto py-3 font-black text-black shadow-lg shadow-[#354a35]' href='https://www.instagram.com/vende.po/'>Contactar!</a>
        </div>
    </div>
  )
}

export default Hero