import React from 'react'

const Contacto = () => {
  return (
    <div id='contacto' className='w-full h-full '>
        <div className='w-[90%] mx-auto py-12'>
            <h1 className='font-bold text-3xl md:text-5xl text-[#00df9a] text-center px-8 mb-3'>
                Contacto
            </h1>
            <div>
              <form className='flex flex-col gap-3 p-2 w-[90%] md:w-[70%] mx-auto'>

                <div className='flex flex-col gap-3 md:flex-row'>
                  <input type="text" placeholder='Tu nombre' className='p-3 flex w-full rounded-md'/>
                  <input type="email" placeholder='email@email.com' className='p-3 flex w-full rounded-md'/>
                </div>

                <textarea name="contactoTA" id="" className='p-3 flex w-full rounded-md' cols="20" rows="10"></textarea>

                <input type="submit" className='bg-[#00df9a] w-[200px] rounded-md mx-auto py-3 px-6 font-black text-black hover:scale-105 duration-300'/>
              </form>
            </div>
        </div>
    </div>
  )
}

export default Contacto