import React from 'react'
import NavbarBlog from '../components/NavbarBlog'
import Footer from '../components/Footer'
import Img from '../assets/SSEcommerce.webp'
import { Link } from 'react-router-dom'

const Blog = () => {
  return (
    <div>
        <NavbarBlog/>
        <div className='backdrop-blur bg-white/80 w-[90%] py-8 h-full min-h-[80vh] mx-auto mt-20 '>
        
          <div>
            <div className='pb-4'>
              <h1 className='font-bold text-3xl text-center pt-2 text-[#2e6c58]'>Blog Informático</h1>
            </div>

            <div className='flex flex-col md:grid md:grid-cols-2 w-[90%] mx-auto md:gap-4 gap-2'>

              <Link to="/blog/1">
                <div className='px-3 py-1 mx-auto bg-black/85 backdrop-blur-xl border-solid hover:border-8 hover:border-r-[#00df9a] text-white flex flex-col items-start duration-300'>
                  <img src={Img} alt="" className='w-[90%] mx-auto' />
                  <div className='py-2 pl-3'>
                    <h1>Página Ecommerce</h1>
                    <p>15-04-2024, por Joel Dev</p>
                  </div>
                </div>
              </Link>


            </div>


          </div>


        </div>


        <Footer/>

    </div>
  )
}

export default Blog