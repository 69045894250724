import React from 'react'
import { FaLaptopCode } from "react-icons/fa6";

const Servicios = () => {
  return (
    <div id='servicios' className='w-full h-full min-h-[100vh] flex items-center text-white'>
        <div className='w-[90%] m-auto items-center py-16 '>
            <h1 className='font-bold text-3xl md:text-5xl text-center px-8'>
                Nuestros Servicios
            </h1>
            <div className='md:grid w-full md:grid-cols-3 flex flex-col items-center justify-center md:gap-4 gap-16 py-8 mx-auto'>
                <div className='w-full flex flex-col mx-auto items-center hover:scale-105 duration-300 hover:text-[#00df9a]'>
                    <FaLaptopCode size={60} className='mx-auto m-2' color='#00df9a'/>
                    <h1 className='text-2xl font-bold '>Desarrollo Web</h1>
                    <p className='w-[80%] text-xl text-center mx-auto'>
                        Ofrecemos una Web para tu marca/negocio/portafolio. 
                    </p>
                </div>
                <div className='w-full flex flex-col mx-auto items-center hover:scale-105 duration-300 hover:text-[#00df9a]'>
                    <FaLaptopCode size={60} className='mx-auto m-2' color='#00df9a'/>
                    <h1 className='text-2xl font-bold'>Diseño Web</h1>
                    <p className='w-[80%] text-xl text-center mx-auto'>
                        Diseñamos tu web!
                    </p>
                </div>
                <div className='w-full flex flex-col mx-auto items-center hover:scale-105 duration-300 hover:text-[#00df9a]'>
                    <FaLaptopCode size={60} className='mx-auto m-2' color='#00df9a'/>
                    <h1 className='text-2xl font-bold'>Software a Medida</h1>
                    <p className='w-[80%] text-xl text-center mx-auto'>
                        Escuchamos tus necesidades y abordamos el problema creando un software funcional
                    </p>
                </div>
                
            </div>

        </div>
    </div>
  )
}

export default Servicios