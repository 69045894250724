import React from 'react'
import { FaRegEye, FaBookOpen } from "react-icons/fa";
import { GoGoal } from "react-icons/go";

const Nosotros = () => {
  return (
    <div id='nosotros' className='w-full h-full min-h-[100vh] py-16 flex items-center bg-white'>
        <div className='w-[90%] m-auto items-center '>
            <h1 className='font-bold text-3xl md:text-5xl text-center px-8 '>
                ¿Quiénes Somos?
            </h1>
            <div className=' mx-auto'>
                <div className='flex flex-col md:grid md:grid-cols-2 gap-8 md:gap-20 text-center py-8'>
                    <div>
                        <p className='md:text-xl text-lg'>Somos un grupo de Programadores <strong> motivados a impulsar tu presencia en el internet</strong> aportándole a tu marca!.</p>
                    </div>
                    <div>
                        <p className='md:text-xl text-lg'>Queremos escuchar tu idea y <strong>volverla realidad</strong>.</p>
                    </div>
                </div>
            </div>

            <div className='flex flex-col md:grid md:grid-cols-3 pb-8 md:gap-20 md:py-12 py-8 gap-8 w-[80%] mx-auto'>

                <div className='flex md:flex-col mx-auto w-full items-center justify-center gap-4 hover:scale-105 duration-300'>
                    <div className='flex mx-auto'>
                        <FaBookOpen size={70} color='#37826a'/> 
                    </div>
                    <p className='md:text-xl text-lg'><strong className='pr-2'>Misión:</strong>Trabajamos de manera Ágil, adaptándonos a tus necesidades, entregando calidad.</p>
                </div>

                <div className='flex md:flex-col mx-auto w-full items-center justify-center gap-4 hover:scale-105 duration-300'>
                    <div className='flex mx-auto'>
                        <FaRegEye size={70} color='#37826a'/> 
                    </div>
                    <p className='md:text-xl text-lg'><strong className='pr-2'>Visión:</strong>Pretendemos impulsar tu marca para tener una presencia sólida en el internet.</p>
                </div>

                <div className='flex md:flex-col mx-auto w-full items-center justify-center gap-4 hover:scale-105 duration-300'>
                    <div className='flex mx-auto'>
                        <GoGoal  size={70} color='#37826a'/> 
                    </div>
                    <p className='md:text-xl text-lg'><strong className='pr-2'>Objetivos:</strong>Crear relaciones de negocio, a parte de potenciar tu marca, hacer software a medida.</p>
                </div>


            </div>

        </div>
    </div>
  )
}

export default Nosotros