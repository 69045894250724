import React from 'react'
import FotoUno from '../assets/SSCabana.webp'
import FotoDos from '../assets/SSJalla.webp'
import FotoTres from '../assets/SSEcommerce.webp'
import FotoCuatro from '../assets/SSAguaMantos.webp'
import FotoCinco from '../assets/SSCC.webp'

const Portafolio = () => {
  return (
    <div id='portafolio' className='w-full h-full bg-white'>
        <div className='w-[90%] mx-auto py-12'>
            <h1 className='font-bold text-3xl md:text-5xl text-center px-8'>
                Portafolio
            </h1>

            <div className='w-full h-full grid lg:grid-cols-2 gap-8 py-12'>

                
                <div className='hover:scale-105 duration-300 w-[80%] md:w-[90%] lg:w-[100%] mx-auto shadow-lg shadow-[#00df9882]'>
                    <a href="https://programaconerick.site/" target='_blank' rel="noreferrer">

                        <img src={FotoUno} alt="Portafolio web Cabañas Playa Norte" />
                    </a>
                </div>
                <div className='hover:scale-105 duration-300 w-[80%] md:w-[90%] lg:w-[100%] mx-auto shadow-lg shadow-[#00df9882]'>
                    <a href="https://construccionesconser.com/" target='_blank' rel="noreferrer">

                        <img src={FotoCinco} alt="Portafolio web e-commerce" />
                    </a>
                </div>
                <div className='hover:scale-105 duration-300 w-[80%] md:w-[90%] lg:w-[100%] mx-auto shadow-lg shadow-[#00df9882]'>
                    <a href="https://aguapurificadalosmantos.cl/about.html" target='_blank' rel="noreferrer">

                        <img src={FotoCuatro} alt="Portafolio web e-commerce" />
                    </a>
                </div>
                <div className='hover:scale-105 duration-300 w-[80%] md:w-[90%] lg:w-[100%] mx-auto shadow-lg shadow-[#00df9882]'>
                    <a href="https://jallas.netlify.app/" target='_blank' rel="noreferrer">
                        <img src={FotoDos} alt="Portafolio web grupo de música musica Jalla Jallas Coquimbo" />
                    </a>
                    
                </div>
                <div className='hover:scale-105 duration-300 w-[80%] md:w-[90%] lg:w-[100%] mx-auto shadow-lg shadow-[#00df9882]'>
                    <a href="https://ecommercechile.netlify.app/" target='_blank' rel="noreferrer">

                        <img src={FotoTres} alt="Portafolio web e-commerce" />
                    </a>
                </div>


            </div>

        </div>
    </div>
  )
}

export default Portafolio