import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Blog from './Pages/Blog'
import ArticuloUno from './Pages/Articulos/ArticuloUno';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },
  {
    path: "/blog",
    element: <Blog/>
  },
  {
    path: "/blog/1",
    element: <ArticuloUno/> 
  }
])

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

